<script>
import { required, email } from "vuelidate/lib/validators";
import { MazPhoneNumberInput } from "maz-ui";
import axios from "axios";

const apiUrl = "https://rmobility.ovh";
/*import {
 authMethods,
  authFackMethods,
  notificationMethods,
} from "@/state/helpers";*/

export default {
  data() {
    return {
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,

      designation: "",
      ifu: "",
      rccm: "",
      ville: "",
      email: "",
      password: "",
      responsable: "",
      tel: "",
      indicatifTel: "+229",
    };
  },
  components: { MazPhoneNumberInput },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  validations: {
    email: { required, email },
    password: { required },
    ville: { required },
    responsable: { required },
    tel: { required },
    designation: { required },
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  methods: {
    /*...authMethods,
    ...authFackMethods,
    ...notificationMethods,*/
    // Try to register the user in with the email, username
    // and password they provided.
    async tryToRegisterIn() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        console.log("Invalid:");
        return;
      } else {
        const {
          designation,
          ifu,
          rccm,
          ville,
          email,
          password,
          responsable,
          tel,
          indicatifTel,
        } = this;

        try {
          const options = {
            method: "POST",
            url: `${apiUrl}/agence/register`,
            data: {
              designation,
              ifu,
              rccm,
              ville,
              email,
              password,
              responsable,
              tel,
              indicatifTel,
            },
            headers: {},
          };

          const resp = await axios(options);

          if (!resp) throw new Error("Error from API: " + resp);

          return resp;
        } catch (err) {
          console.error("[ERROR]:", err);
          const resp = err.response;
          if (resp && resp.status === 401) {
            sessionStorage.removeItem("accessToken");
          } else {
            throw new Error(err);
          }
        }

        /*console.log("data:", data);
        if (data.data) {
          this.registerSuccess = true;
        } else {
          this.regError = "";
          this.isRegisterError = true;
        }*/
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-6">
            <div
              class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <!-- <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/logo-dark.png" height="20" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">Register account</h4>
                        <p class="text-muted">Get your free Nazox account now.</p>
                      </div> -->

                      <div class="p-2 mt-5">
                        <b-alert
                          v-model="registerSuccess"
                          class="mt-3"
                          variant="success"
                          dismissible
                          >Registration successfull.</b-alert
                        >

                        <b-alert
                          v-model="isRegisterError"
                          class="mt-3"
                          variant="danger"
                          dismissible
                          >{{ regError }}</b-alert
                        >

                        <b-alert
                          variant="danger"
                          class="mt-3"
                          v-if="notification.message"
                          show
                          dismissible
                          >{{ notification.message }}</b-alert
                        >

                        <form
                          class="form-horizontal"
                          @submit.prevent="tryToRegisterIn"
                        >
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="">Nom de la société</label>
                                <input
                                  type="text"
                                  name=""
                                  id=""
                                  class="form-control"
                                  v-model="designation"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="">Ville</label>
                                <input
                                  type="text"
                                  name=""
                                  id=""
                                  class="form-control"
                                  v-model="ville"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="">IFU</label>
                                <input
                                  type="text"
                                  name=""
                                  id=""
                                  class="form-control"
                                  v-model="ifu"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="">RCCM</label>
                                <input
                                  type="text"
                                  name=""
                                  id=""
                                  class="form-control"
                                  v-model="rccm"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="">Email</label>
                                <input
                                  type="email"
                                  name=""
                                  id=""
                                  class="form-control"
                                  v-model="email"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="">Mot de passe</label>
                                <input
                                  type="password"
                                  name=""
                                  id=""
                                  class="form-control"
                                  v-model="password"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="">Numéro de téléphone</label>
                                <MazPhoneNumberInput
                                  :class="{
                                    'is-invalid':
                                      submitform && $v.phoneNumber.$error,
                                  }"
                                  v-model="tel"
                                  default-country-code="BJ"
                                  :translations="{
                                    countrySelectorLabel: 'Code pays',
                                    countrySelectorError: 'Choisir un pays',
                                    phoneNumberLabel: 'Numéro de téléphone',
                                    example: 'Exemple :',
                                  }"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="">Nom du responsable</label>
                                <input
                                  type="text"
                                  name=""
                                  id=""
                                  class="form-control"
                                  v-model="responsable"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              S'inscrire
                            </button>
                          </div>
                        </form>

                        <!-- <form
                          class="form-horizontal"
                          @submit.prevent="tryToRegisterIn"
                        >
                          <div class="row">
                            <div class="col-md-6">
                              <div
                                class="form-group auth-form-group-custom mb-4"
                              >
                                
                                <label>Nom de la société</label>
                                <input
                                  v-model="user.username"
                                  type="text"
                                  class="form-control"
                                  id="username"
                                  :class="{
                                    'is-invalid':
                                      submitted && $v.user.username.$error,
                                  }"
                                />
                                <div
                                  v-if="submitted && !$v.user.username.required"
                                  class="invalid-feedback"
                                >
                                  Nom d'utilisateur requis.
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div
                                class="form-group auth-form-group-custom mb-4"
                              >
                                <i
                                  class="ri-user-2-line auti-custom-input-icon"
                                ></i>
                                <label for="username">Nom</label>
                                <input
                                  v-model="user.username"
                                  type="text"
                                  class="form-control"
                                  id="username"
                                  :class="{
                                    'is-invalid':
                                      submitted && $v.user.username.$error,
                                  }"
                                />
                                <div
                                  v-if="submitted && !$v.user.username.required"
                                  class="invalid-feedback"
                                >
                                  Nom d'utilisateur requis.
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-6">
                              <div
                                class="form-group auth-form-group-custom mb-4"
                              >
                                <i
                                  class="ri-mail-line auti-custom-input-icon"
                                ></i>
                                <label for="useremail">Email</label>
                                <input
                                  v-model="user.email"
                                  type="email"
                                  class="form-control"
                                  id="useremail"
                                  placeholder="Enter email"
                                  :class="{
                                    'is-invalid':
                                      submitted && $v.user.email.$error,
                                  }"
                                />
                                <div
                                  v-if="submitted && $v.user.email.$error"
                                  class="invalid-feedback"
                                >
                                  <span v-if="!$v.user.email.required"
                                    >Email requis.</span
                                  >
                                  <span v-if="!$v.user.email.email"
                                    >Please enter valid email.</span
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div
                                class="form-group auth-form-group-custom mb-4"
                              >
                                <i
                                  class="ri-mail-line auti-custom-input-icon"
                                ></i>
                                <label for="useremail">Email</label>
                                <input
                                  v-model="user.email"
                                  type="email"
                                  class="form-control"
                                  id="useremail"
                                  placeholder="Enter email"
                                  :class="{
                                    'is-invalid':
                                      submitted && $v.user.email.$error,
                                  }"
                                />
                                <div
                                  v-if="submitted && $v.user.email.$error"
                                  class="invalid-feedback"
                                >
                                  <span v-if="!$v.user.email.required"
                                    >Email requis.</span
                                  >
                                  <span v-if="!$v.user.email.email"
                                    >Please enter valid email.</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-6">
                              <div
                                class="form-group auth-form-group-custom mb-4"
                              >
                                <i
                                  class="ri-mail-line auti-custom-input-icon"
                                ></i>
                                <label for="useremail">Email</label>
                                <input
                                  v-model="user.email"
                                  type="email"
                                  class="form-control"
                                  id="useremail"
                                  placeholder="Enter email"
                                  :class="{
                                    'is-invalid':
                                      submitted && $v.user.email.$error,
                                  }"
                                />
                                <div
                                  v-if="submitted && $v.user.email.$error"
                                  class="invalid-feedback"
                                >
                                  <span v-if="!$v.user.email.required"
                                    >Email requis.</span
                                  >
                                  <span v-if="!$v.user.email.email"
                                    >Please enter valid email.</span
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div
                                class="form-group auth-form-group-custom mb-4"
                              >
                                <i
                                  class="ri-mail-line auti-custom-input-icon"
                                ></i>
                                <label for="useremail">Email</label>
                                <input
                                  v-model="user.email"
                                  type="email"
                                  class="form-control"
                                  id="useremail"
                                  placeholder="Enter email"
                                  :class="{
                                    'is-invalid':
                                      submitted && $v.user.email.$error,
                                  }"
                                />
                                <div
                                  v-if="submitted && $v.user.email.$error"
                                  class="invalid-feedback"
                                >
                                  <span v-if="!$v.user.email.required"
                                    >Email requis.</span
                                  >
                                  <span v-if="!$v.user.email.email"
                                    >Please enter valid email.</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-6">
                              <div
                                class="form-group auth-form-group-custom mb-4"
                              >
                                <i
                                  class="ri-mail-line auti-custom-input-icon"
                                ></i>
                                <label for="useremail">Email</label>
                                <input
                                  v-model="user.email"
                                  type="email"
                                  class="form-control"
                                  id="useremail"
                                  placeholder="Enter email"
                                  :class="{
                                    'is-invalid':
                                      submitted && $v.user.email.$error,
                                  }"
                                />
                                <div
                                  v-if="submitted && $v.user.email.$error"
                                  class="invalid-feedback"
                                >
                                  <span v-if="!$v.user.email.required"
                                    >Email requis.</span
                                  >
                                  <span v-if="!$v.user.email.email"
                                    >Please enter valid email.</span
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div
                                class="form-group auth-form-group-custom mb-4"
                              >
                                <i
                                  class="ri-mail-line auti-custom-input-icon"
                                ></i>
                                <label for="useremail">Email</label>
                                <input
                                  v-model="user.email"
                                  type="email"
                                  class="form-control"
                                  id="useremail"
                                  placeholder="Enter email"
                                  :class="{
                                    'is-invalid':
                                      submitted && $v.user.email.$error,
                                  }"
                                />
                                <div
                                  v-if="submitted && $v.user.email.$error"
                                  class="invalid-feedback"
                                >
                                  <span v-if="!$v.user.email.required"
                                    >Email requis.</span
                                  >
                                  <span v-if="!$v.user.email.email"
                                    >Please enter valid email.</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i
                              class="ri-lock-2-line auti-custom-input-icon"
                            ></i>
                            <label for="userpassword">Mot de passe</label>
                            <input
                              v-model="user.password"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="Enter password"
                              :class="{
                                'is-invalid':
                                  submitted && $v.user.password.$error,
                              }"
                            />
                            <div
                              v-if="submitted && !$v.user.password.required"
                              class="invalid-feedback"
                            >
                              Password is required.
                            </div>
                          </div>

                          <div class="text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              S'inscrire
                            </button>
                          </div>

                         
                        </form> -->
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          Vous avez déjà un compte ?
                          <router-link
                            tag="a"
                            to="/login"
                            class="font-weight-medium text-primary"
                            >Connectez-vous</router-link
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
